import { graphql, Link } from "gatsby"
import React, { useState } from "react"
import Helmet from "react-helmet"
import PortableText from "react-portable-text"
import Container from "../components/Container/Container"
import Enquiry from "../components/Global/Enquiry"
import GraphQLErrorList from "../components/graphql-error-list"
import InView from "../components/InView"
import Seo from "../components/SEO/SEO"
import OverlayForm from "../components/Global/OverlayForm"

import Image from "gatsby-plugin-sanity-image"

// import React Player
import ReactPlayer from "react-player"

// import Swiper core and required modules
import { A11y, Autoplay, EffectFade } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/effect-fade"

// components/something.js
import Sensor from "../components/sensor"

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      defaultImage {
        asset {
          _id
        }
      }
      enquiryCTAImage {
        ...Image
      }
      enquiryCTATitle
      enquiryCTAText
    }
    sanityHome {
      hero {
        kind
        heroImage {
          ...Image
          alt
        }
        heroSlideshow {
          images {
            ...Image
            alt
          }
        }
        heroVideoUrl
      }
      heroTextSlides {
        text
        tagline
        linkText
        link {
          slug {
            current
          }
        }
      }
      content {
        ... on SanityHomeOurProjects {
          _key
          _type
          linkText
          text
          textSecond
          projects {
            homeImage {
              ...Image
              alt
            }
            mainImage {
              ...Image
              alt
            }
            slug {
              current
            }
            title
          }
        }
        ... on SanityHomeEnvironment {
          _key
          _type
          linkText
          liveable
          roofs
          solar
          text
          trees
          water
          wheat
        }
        ... on SanityHomeFeatureNews {
          _key
          _type
          article {
            mainImage {
              ...Image
              alt
            }
            _rawExcerpt
            slug {
              current
            }
          }
        }
        ... on SanityFullBleedImage {
          _key
          _type
          image {
            ...Image
            alt
          }
        }
        ... on SanityHomeOurStory {
          _key
          _type
          title
          description
          linkText
          link
          links {
            image {
              ...Image
              alt
            }
            title
            description
            link
          }
        }
      }
    }
  }
`

const IndexPage = (props) => {
  const { data, errors } = props
  const [playing, setPlaying] = useState(true)
  const [loaded, setLoaded] = useState(false)

  if (errors) {
    return (
      <>
        <GraphQLErrorList errors={errors} />
      </>
    )
  }

  const site = (data || {}).site

  const HeroSlide = ({ item }) => (
    <>
      <Image
        {...item}
        width={1800}
        height={1350}
        alt={item.image.alt}
        className="swiper-lazy"
      />
    </>
  )

  const SwiperSlideContent = ({ item }) => (
    <>
      {item.text && (
        <h1 className="font-serif text-[32px] leading-[120%] md:text-[40px] md:leading-[130%]">
          {item.text}
        </h1>
      )}
      {item.tagline && (
        <p className="text-sm leading-[24px] my-4 lg:my-6">{item.tagline}</p>
      )}
      {item.link && item.linkText && (
        <Link
          className="mt-2 text-sm link link--white"
          to={`/project/${item.link.slug.current}`}
        >
          {item.linkText}
        </Link>
      )}
    </>
  )

  const ProjectSlideContent = ({ item }) => {
    var image = item.mainImage
    if (item.homeImage) {
      image = item.homeImage
    }

    return (
      <>
        <div className="pb-6 lg:pb-10">
          <div className="overflow-hidden">
            <Link to={`/project/${item.slug.current}`}>
              <Image
                {...image}
                width={580}
                height={739}
                alt={image.alt}
                className={
                  `transition-transform duration-500 ease-in-out md:hover:transform md:hover:scale-[1.05] lazy ` +
                  (loaded && " loaded")
                }
                onLoad={() => setLoaded(true)}
              />
            </Link>
          </div>
          <div>
            <h4 className="font-serif text-[32px] leading-[1.2] mt-10 mb-4">
              {item.title}
            </h4>
            <Link className="text-sm link" to={`/project/${item.slug.current}`}>
              View project
            </Link>
          </div>
        </div>
      </>
    )
  }

  const OurStoryLinks = ({ item }) => (
    <>
      <li>
        <Link
          className="flex gap-6 items-center p-6 w-full md:py-10 md:px-6 group"
          to={`${item.link}`}
        >
          <div className="w-[56px] h-[56px] rounded-[56px] md:w-[80px] md:h-[80px] md:rounded-[80px] flex-none overflow-hidden">
            <Image
              {...item.image}
              width={120}
              height={120}
              alt={item.image.alt}
              className={
                `transition-transform duration-500 ease-in-out md:group-hover:transform md:group-hover:scale-[1.10] lazy w-full object-cover object-center ` +
                (loaded && " loaded")
              }
              onLoad={() => setLoaded(true)}
            />
          </div>
          <div>
            {item.title && (
              <h4 className="text-[20px] leading-[1.6] font-serif">
                {item.title}
              </h4>
            )}
            {item.description && (
              <p className="text-[14px] leading-[24px] text-[#666] max-w-[492px] hidden md:inline-block">
                {item.description}
              </p>
            )}
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            className="ml-auto md:hidden"
          >
            <path
              stroke="#1B1B1B"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M3.333 8h9.334m0 0L8 3.335m4.667 4.667L8 12.667"
            ></path>
          </svg>
        </Link>
      </li>
    </>
  )

  const renderPauseButton = () => {
    return (
      <div className="hidden absolute right-10 bottom-10 z-10 md:inline-block">
        <button
          onClick={() => {
            setPlaying(!playing)
            console.log("Playing state is now:", !playing)
          }}
          className="inline-flex items-center text-white"
        >
          {playing ? (
            <>
              <span className="sr-only">Pause</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34">
                <path
                  fill="none"
                  stroke="#FFF"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M13 21.8v-9.6m8 9.6v-9.6M33 17c0 8.837-7.163 16-16 16S1 25.837 1 17 8.163 1 17 1s16 7.163 16 16z"
                  opacity="0.8"
                ></path>
              </svg>
            </>
          ) : (
            <>
              <span className="sr-only">Play</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34">
                <g
                  fill="none"
                  fillRule="evenodd"
                  stroke="#FFF"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                >
                  <path
                    d="M17 33c8.836 0 16-7.164 16-16 0-8.837-7.164-16-16-16C8.163 1 1 8.163 1 17c0 8.836 7.163 16 16 16z"
                    opacity="0.805"
                  ></path>
                  <path
                    d="M13 12.145c0-.764 0-1.146.16-1.36a.8.8 0 01.583-.318c.266-.019.587.188 1.23.6l7.552 4.856c.558.359.836.538.932.766a.8.8 0 010 .622c-.096.228-.374.407-.932.766l-7.553 4.855c-.642.413-.963.62-1.229.6a.8.8 0 01-.583-.318c-.16-.213-.16-.595-.16-1.358v-9.711z"
                    opacity="0.8"
                  ></path>
                </g>
              </svg>
            </>
          )}
        </button>
      </div>
    )
  }

  const SwiperSlideText = () => {
    return (
      <div className="container absolute bottom-10 left-1/2 z-10 text-white transform -translate-x-1/2">
        <Swiper
          className="slider--fade max-w-[680px] mx-0"
          modules={[Autoplay, A11y, EffectFade]}
          slidesPerView={1}
          effect={"fade"}
          loop={true}
          speed={1000}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
        >
          {data.sanityHome.heroTextSlides.map((item, index) => (
            <SwiperSlide>
              <SwiperSlideContent item={item} key={index} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    )
  }

  return (
    <>
      <Seo
        title={site.title}
        description={site.description}
        image={site.defaultImage}
      />
      <Helmet
        bodyAttributes={{
          class: "home",
        }}
      />
      <Container>
        <div className="box-border border-b border-grey-default">
          {data.sanityHome.hero.kind === "slideshow" && (
            <div className="relative mb-4 hero-wrapper md:mb-0">
              <SwiperSlideText />
              <Swiper
                className="w-screen h-screen"
                modules={[Autoplay, A11y, EffectFade]}
                slidesPerView={1}
                effect={"fade"}
                loop={true}
                lazy={true}
                speed={1000}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
              >
                {data.sanityHome.hero.heroSlideshow.images.map(
                  (item, index) => (
                    <SwiperSlide>
                      <HeroSlide item={item} key={index} />
                    </SwiperSlide>
                  )
                )}
              </Swiper>
            </div>
          )}

          {data.sanityHome.hero.kind === "video" && (
            <div className="relative">
              <SwiperSlideText />
              {renderPauseButton()}
              <div
                className={
                  `hero-wrapper relative video-wrapper lazy ` +
                  (loaded && " loaded")
                }
              >
                <div className="hero-wrapper__inner">
                  <ReactPlayer
                    width="100%"
                    height="100%"
                    playing={playing}
                    playsinline={true}
                    loop
                    muted
                    config={{
                      file: {
                        attributes: {
                          autoPlay: true,
                          muted: true,
                        },
                      },
                    }}
                    url={data.sanityHome.hero.heroVideoUrl}
                    onReady={() => setLoaded(true)}
                  />
                </div>
                <span className="absolute top-0 left-0 w-full h-full bg-[linear-gradient(356deg,rgba(0,0,0,0.40)4.21%,rgba(0,0,0,0.00)97.19%)]"></span>
              </div>
            </div>
          )}

          {data.sanityHome.hero.kind === "image" && (
            <div className="relative mb-4 hero-wrapper md:mb-0">
              <SwiperSlideText />
              <Image
                {...data.sanityHome.hero.heroImage}
                width={1600}
                height={880}
                quality={50}
                alt={data.sanityHome.hero.heroImage.alt}
                className={`lazy ` + (loaded && " loaded")}
                onLoad={() => setLoaded(true)}
              />
            </div>
          )}

          {data.sanityHome.content.length > 0 &&
            data.sanityHome.content.map((content, i) => (
              <>
                {content._type === "homeOurProjects" && (
                  <InView key={i}>
                    <div className="box-border border-b border-grey-default">
                      <div className="container">
                        <div className="max-w-[600px] pb-6 pt-10 lg:py-10">
                          <h3 className="mb-10 lg:mb-[120px]">
                            Upcoming projects
                          </h3>
                          <div className="mt-3 md:mt-auto">
                            <h2 className="font-serif text-[24px] leading-[1.4] md:text-[32px] md:leading-[1.2]">
                              {content.text}
                            </h2>
                            <Link
                              className="mt-6 text-sm link lg:mt-10"
                              to="/projects"
                            >
                              {content.linkText}
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="mx-auto w-full max-w-full recent-projects">
                        <Swiper
                          modules={[A11y]}
                          slidesPerView={1.3}
                          speed={1000}
                          breakpoints={{
                            1024: {
                              slidesPerView: 3,
                            },
                          }}
                        >
                          {content.projects.map((item, index) => (
                            <SwiperSlide key={index}>
                              <ProjectSlideContent item={item} key={index} />
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </div>
                    </div>
                  </InView>
                )}

                {content._type === "homeOurStory" && (
                  <InView key={i}>
                    <div className="pt-10 mx-auto w-full max-w-full md:pl-10 lg:py-0 md:mt-10">
                      <div className="flex flex-col md:grid md:grid-cols-12">
                        <div className="flex flex-col order-1 px-6 md:pl-0 md:col-span-6 md:pr-10">
                          {content.title && (
                            <h3 className="hidden md:inline-block">
                              {content.title}
                            </h3>
                          )}
                          <div className="md:mt-auto">
                            {content.title && (
                              <h3 className="inline-block mb-10 md:hidden">
                                {content.title}
                              </h3>
                            )}
                            {content.description && (
                              <h2 className="font-serif text-[24px] leading-[1.4] lg:text-[32px] lg:leading-[1.2]">
                                {content.description}
                              </h2>
                            )}
                            {content.link && content.linkText && (
                              <Link
                                className="my-10 text-sm link"
                                to={`${content.link}`}
                              >
                                {content.linkText}
                              </Link>
                            )}
                          </div>
                        </div>
                        <div className="box-border order-2 md:col-span-6 md:border-l md:border-grey-default">
                          <ul className="*:border-t *:md:border-t-0 *:md:border-b *:border-grey-default last-of-type:*:md:border-b-0">
                            {content.links.map((item, index) => (
                              <OurStoryLinks item={item} key={index} />
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </InView>
                )}

                {content._type === "homeFeatureNews" && (
                  <InView key={i}>
                    <div className="container py-10 lg:py-0 md:mt-10">
                      <div className="flex flex-col md:grid md:grid-cols-12">
                        <div className="flex flex-col order-1 md:col-span-5 md:pr-10">
                          <h3 className="hidden md:inline-block text-[20px] leading-[1.6]">
                            <Link to="/news">News</Link>
                          </h3>
                          <div className="md:mt-auto">
                            <h3 className="inline-block md:hidden  text-[20px] leading-[1.6] mb-10">
                              <Link to="/news">News</Link>
                            </h3>
                            <h2>
                              {content.article._rawExcerpt && (
                                <PortableText
                                  className="font-serif line-clamp-2 text-[24px] leading-[1.4] lg:text-[32px] lg:leading-[1.2]"
                                  content={content.article._rawExcerpt}
                                  serializers={{
                                    internalLink: ({ reference, children }) => {
                                      const href = `/${reference._type}/${reference.slug.current}`
                                      return <Link to={href}>{children}</Link>
                                    },
                                    link: ({ href, blank, children }) => {
                                      return (
                                        <a
                                          href={href}
                                          target={blank ? "_blank" : "_self"}
                                          rel={blank ? "noreferrer" : undefined}
                                        >
                                          {children}
                                        </a>
                                      )
                                    },
                                  }}
                                />
                              )}
                            </h2>
                            <Link
                              className="my-10 text-sm link"
                              to={`/news/${content.article.slug.current}`}
                            >
                              Read more
                            </Link>
                          </div>
                        </div>
                        <div className="box-border order-2 md:col-span-7 md:pl-10 md:pb-10 md:border-l md:border-grey-default">
                          <Link
                            className="block overflow-hidden"
                            to={`/news/${content.article.slug.current}`}
                          >
                            <Image
                              {...content.article.mainImage}
                              width={1000}
                              height={680}
                              alt={content.article.mainImage.alt}
                              className={
                                `transition-transform duration-500 ease-in-out md:hover:transform md:hover:scale-[1.05] lazy ` +
                                (loaded && " loaded")
                              }
                              onLoad={() => setLoaded(true)}
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </InView>
                )}

                {content._type === "fullBleedImage" && (
                  <InView key={i}>
                    <Image
                      {...content.image}
                      width={1600}
                      height={880}
                      alt={content.image.alt}
                      className={`lazy ` + (loaded && " loaded")}
                      onLoad={() => setLoaded(true)}
                    />
                  </InView>
                )}

                {content._type === "homeEnvironment" && (
                  <div className="overflow-hidden bg-off-white" key={i}>
                    <InView>
                      <div className="relative md:border-b md:border-grey-default md:box-border">
                        <div className="container">
                          <div className="pt-6 md:pt-10 mb-10 md:mb-[120px]">
                            <h2 className="font-serif mb-2 text-[32px] leading-[1.2] md:max-w-[620px]">
                              {content.text}
                            </h2>
                            <Link
                              className="inline-flex items-center text-sm text-grey-base link"
                              to="/sustainability"
                            >
                              {content.linkText}
                            </Link>
                          </div>
                        </div>
                        <div className="container flex flex-col md:grid md:grid-cols-12 md:gap-10 md:mt-10">
                          <div className="md:col-span-4 md:pb-10 md:border-r md:border-grey-default md:box-border">
                            <div className="flex flex-col pt-10 md:pt-0 calc">
                              <Sensor once>
                                {({ isVisible }) => (
                                  <div
                                    className={`animation animation--tree ${
                                      isVisible && "animate"
                                    }`}
                                  >
                                    <span className="delay-[.5s]"></span>
                                    <span className="delay-[.5s]"></span>
                                    <span className="delay-[.5s]"></span>
                                    <span className="delay-[.5s]"></span>
                                  </div>
                                )}
                              </Sensor>
                              <div className="pb-5 mt-auto md:pb-0">
                                <h2 className="font-serif text-[24px] leading-[1.2] max-w-[420px]">
                                  {content.trees}
                                </h2>
                              </div>
                              <span className="w-screen mx-[-20px] h-px bg-grey-default md:hidden"></span>
                            </div>
                          </div>
                          <div className="md:col-span-4 md:pb-10 md:border-r md:border-grey-default md:box-border">
                            <div className="flex flex-col pt-10 md:pt-0 calc">
                              <Sensor once>
                                {({ isVisible }) => (
                                  <div
                                    className={`animation animation--solar ${
                                      isVisible && "animate"
                                    }`}
                                  >
                                    <span className="delay-[1s]"></span>
                                    <span className="delay-[1s]"></span>
                                    <span className="delay-[1s]"></span>
                                    <span className="delay-[1s]"></span>
                                  </div>
                                )}
                              </Sensor>
                              <div className="mt-auto">
                                <h2 className="pb-5 md:pb-0 font-serif text-[24px] leading-[1.2] max-w-[420px]">
                                  {content.solar}
                                </h2>
                              </div>
                              <span className="w-screen mx-[-20px] h-px bg-grey-default md:hidden"></span>
                            </div>
                          </div>
                          <div className="md:col-span-4 md:pb-10">
                            <div className="flex flex-col pt-10 md:pt-0 calc">
                              <Sensor once>
                                {({ isVisible }) => (
                                  <div
                                    className={`animation animation--water ${
                                      isVisible && "animate"
                                    }`}
                                  >
                                    <span className="delay-[1.5s]"></span>
                                    <span className="delay-[1.5s]"></span>
                                    <span className="delay-[1.5s]"></span>
                                    <span className="delay-[1.5s]"></span>
                                  </div>
                                )}
                              </Sensor>
                              <div className="pb-5 mt-auto md:pb-0">
                                <h2 className="font-serif text-[24px] leading-[1.2] max-w-[420px]">
                                  {content.water}
                                </h2>
                              </div>
                              <span className="w-screen mx-[-20px] h-px bg-grey-default md:hidden"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="hidden relative md:flex">
                        <div className="container flex flex-col md:grid md:grid-cols-12 md:gap-10 md:pt-[66px]">
                          <div className="md:col-span-4 md:pb-10 md:border-r md:border-grey-default md:box-border">
                            <div className="flex flex-col pt-10 md:pt-0 calc">
                              <Sensor once>
                                {({ isVisible }) => (
                                  <div
                                    className={`animation animation--roofs ${
                                      isVisible && "animate"
                                    }`}
                                  >
                                    <span className="delay-[2s]"></span>
                                    <span className="delay-[2s]"></span>
                                    <span className="delay-[2s]"></span>
                                    <span className="delay-[2s]"></span>
                                  </div>
                                )}
                              </Sensor>
                              <div className="pb-5 mt-auto md:pb-0">
                                <h2 className="font-serif text-[24px] leading-[1.2] max-w-[420px]">
                                  {content.roofs}
                                </h2>
                              </div>
                              <span className="w-screen mx-[-20px] h-px bg-grey-default md:hidden"></span>
                            </div>
                          </div>
                          <div className="md:col-span-4 md:pb-10 md:border-r md:border-grey-default md:box-border">
                            <div className="flex flex-col pt-10 md:pt-0 calc">
                              <Sensor once>
                                {({ isVisible }) => (
                                  <div
                                    className={`animation animation--liveable ${
                                      isVisible && "animate"
                                    }`}
                                  >
                                    <span className="delay-[2.5s]"></span>
                                    <span className="delay-[2.5s]"></span>
                                    <span className="delay-[2.5s]"></span>
                                    <span className="delay-[2.5s]"></span>
                                  </div>
                                )}
                              </Sensor>
                              <div className="pb-5 mt-auto md:pb-0">
                                <h2 className="font-serif text-[24px] leading-[1.2] max-w-[420px]">
                                  {content.liveable}
                                </h2>
                              </div>
                              <span className="w-screen mx-[-20px] h-px bg-grey-default md:hidden"></span>
                            </div>
                          </div>
                          <div className="md:col-span-4 md:pb-10">
                            <div className="flex flex-col pt-10 md:pt-0 calc">
                              <Sensor once>
                                {({ isVisible }) => (
                                  <div
                                    className={`animation animation--wheat ${
                                      isVisible && "animate"
                                    }`}
                                  >
                                    <span className="delay-[3s]"></span>
                                    <span className="delay-[3s]"></span>
                                    <span className="delay-[3s]"></span>
                                  </div>
                                )}
                              </Sensor>
                              <div className="pb-5 mt-auto md:pb-0">
                                <h2 className="font-serif text-[24px] leading-[1.2] max-w-[420px]">
                                  {content.wheat}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </InView>
                  </div>
                )}
              </>
            ))}
          <InView>
            <Enquiry data={data} />
          </InView>
          <OverlayForm />
        </div>
      </Container>
    </>
  )
}

export default IndexPage
